<template>
  <v-container>
    <v-overlay :z-index="100" :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="grey darken-2"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row class="mt-10">
      <v-col class=".d-none .d-md-flex" cols="3"></v-col>
      <v-col cols="12" md="6">
        <v-card height="200">
          <v-card-title>Login</v-card-title>
          <v-card-text class="text-center">
            <a href="javascript://" @click="googleAuth"><img src="../../assets/google-sign-in-btn.png" width="250" border="0" /></a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data () {
    return {
      emailErrors: [],
      passwordErrors: [],
    }
  },
  computed: {
    loading() {
      return this.$store.state.auth.loading;
    },
  },
  methods: {
    googleAuth() {
      const hello = this.hello;
      hello('google').login({
        scope: 'email',
        force: true
      }).then(() => {
        const authRes = hello('google').getAuthResponse();
        this.$store.dispatch('googleAuth', {
          token: authRes.access_token
        }).then((response) => {
          if (response.status === 'error') {
            this.$store.dispatch('displayError', response.data);
          } else {
            this.$router.push({ name: 'dashboard' });
          }
        }).catch(e => {
          this.$store.dispatch('displayError', 'This account is not authorized: ' + e);
        });
      })
    },
  },
}
</script>

<style scoped>

</style>